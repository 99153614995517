import React from 'react';
import styled from 'styled-components';
import Map from './Map';

const StyledMapSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;

  @media screen and (min-width: 1200px) {
    padding: 8rem 0.2rem;
  }
`;

export default function MapSection() {
  return (
    <StyledMapSection>
      <Map />
    </StyledMapSection>
  );
}
