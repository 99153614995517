import React from 'react';
import styled from 'styled-components';

const StyledTopSection = styled.section`
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgb(242 219 187 / 25%);

  @media screen and (min-width: 767px) {
    height: 25vh;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  margin-bottom: 0;

  @media screen and (max-width: 767px) {
    font-size: 2.6rem;
  }
`;

export default function TopSection({ title }) {
  return (
    <StyledTopSection>
      <Header>
        <Title>{title}</Title>
      </Header>
    </StyledTopSection>
  );
}
