import React from 'react';
import Layout from '../components/Layout';
import TopSection from '../components/TopSection';
import MapSection from '../components/MapSection';
import SEO from '../components/Seo';

export default function FindUs() {
  return (
    <>
      <SEO title="Find Us" />
      <Layout>
        <TopSection title="Find Us" />
        <MapSection />
      </Layout>
    </>
  );
}
