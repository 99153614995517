import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';

import feather from '../assets/images/feather.svg';

const Container = styled.div`
  height: 35rem;
  width: 100%;

  @media screen and (min-width: 1200px) {
    height: 50rem;
    max-width: 120rem;
  }
`;

const Marker = styled.img`
  transform: translate(-100%, -100%);
`;

export default function Map() {
  return (
    // Important! Always set the container height explicitly
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        defaultCenter={{
          lat: 53.7590013,
          lng: -2.6995775,
        }}
        defaultZoom={15}
        options={{
          styles: [
            {
              stylers: [
                { saturation: -100 },
                { gamma: 0.8 },
                { lightness: 20 },
                { visibility: 'on' },
              ],
            },
          ],
        }}
      >
        <Marker
          lat={53.7590013}
          lng={-2.6995775}
          src={feather}
          alt="map marker"
        />
      </GoogleMapReact>
    </Container>
  );
}
